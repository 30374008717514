<template>
    <v-container class="azul-sus">
        <v-card class="ma-auto custom-card" max-width="600">
            <v-card-title class="ma-auto">
                <v-row>
                    <v-col class="text-center">
                        Menu
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="ma-auto">
                <v-row dense class="ma-auto">
                    <v-col dense :sx="12" :ms="12" :md="12" :lg="6" :cols="12">
                        <Sus :current-item="paciente" @atualizaPlus="atualiza"/>
                    </v-col>
                    <v-col dense :sx="12" :ms="12" :md="12" :lg="6" :cols="12">
                        <ListaBiomarcador :atualiza="trgAtualiza"/>
                    </v-col>
                    <v-col dense :sx="12" :ms="12" :md="12" :lg="6" :cols="12">
                        <ListaExameFundoOlho />
                    </v-col>
                    <v-col dense :sx="12" :ms="12" :md="12" :lg="6" :cols="12">
                        <ListaRegular />
                    </v-col>
                    <v-col dense :sx="12" :ms="12" :md="12" :lg="6" :cols="12">
                        <Lista />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    export default{
        name:'Menu',
        components:{
            Sus: () => import("./Sus.vue"),
            Lista: () => import("./Lista.vue"),
            ListaRegular: () => import("./ListaRegular.vue"),
            ListaBiomarcador: () => import("./ListaBiomarcador.vue"),
            ListaExameFundoOlho: () => import("./ListaExameFundoOlho.vue")
},
        data:()=>({
            paciente:{id:null},
            trgAtualiza:false,
            loading:false,
            glicemias:[],
            examesfdos:[],
            regulacoes:[],
            pacientes:[],
        }),
        atualliza(val){
            this.trgAtualiza = val
        },
        /*
        wacth:{
            trgAtualiza(newVal){
                if(newVal){
                    this.get_listas('mutirao-sus/pacientes/?etapa=biomarcador', 'glicemias')
                    this.get_listas('mutirao-sus/pacientes/?etapa=exame-fundo-olho', 'examesfdos')
                    this.get_listas('mutirao-sus/pacientes/?etapa=regular', 'regulacoes')
                    this.get_listas('mutirao-sus/pacientes/?etapa=todos', 'pacientes')
                }
            }
        }
        */
    }
</script>
<style>
.azul-sus{
    background-color: #305f67;
    height: 100%;
}
.custom-card {
    color: #fff !important;
    background-color: #305f67 !important; /* Cor de fundo desejada em hexadecimal */
    border-radius: 8px !important; /* Raio das bordas, ajuste conforme necessário */
    border-color: #fff !important;
  }
</style>